import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailSignup from "../components/EmailSignup"

export default function languages(props) {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title={"Languages"}></SEO>
      <main className="markdown">
        <h1 className="text-center font-bold text-3xl">Language Guides</h1>
        <h2>
          <Link to="/russian">
            Russian{" "}
            <span role="img" aria-label="Russian Flag">
              🇷🇺
            </span>
          </Link>
        </h2>
        <ul>
          <li>
            <Link to="/russian/a-beginners-guide-to-learning-russian">
              A Beginner's Guide to Learning Russian
            </Link>
          </li>
        </ul>
        <h2>
          Chinese{" "}
          <span role="img" aria-label="Chinese Flag">
            🇨🇳
          </span>
        </h2>
        <ul>
          <li>
            <Link to="/blog/im-going-to-learn-3000-chinese-characters-in-120-days/">
              I'm Learning 3,000 Chinese Characters in 120 Days
            </Link>
          </li>
        </ul>
        <br></br>
        <p className="text-center font-semibold mb-3">
          I send updates every Saturday.
        </p>
        <EmailSignup></EmailSignup>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
